$(document).ready(function () {

    $('.opinion-carousel').each(function(){
        var image_amount = $(this).data('amount_images');

        if(image_amount){
            var responsive = {
                0:{
                    'items':1
                },
            }
            if(image_amount > 1){
                responsive[768] = {
                    'items': 2
                }
            }
            if(image_amount > 2){
                responsive[992] = {
                    'items': 3
                }
            }
        }else{
            var responsive = {
                0:{
                    'items':1
                },
            }
        }
        if(!image_amount){
            image_amount = 1;
        }

        $(this).owlCarousel({
            'items': image_amount,
            'loop': true,
            'autoplay': true,
            'autoplayHoverPause': true,
            'nav': true,
            'dots': false,
            'dotsContainer': false,
            'responsive': responsive,
            navText: ["<i class='fas fa-caret-left'></i>", "<i class='fas fa-caret-right'></i>"]
        });
    });

    if(getCookie('price-inquiry-cookie')){
        $('.price-inquiry-menu').addClass(('has-price-inquiry-menu'))
    }

    function getCookie(name) {
        var data = localStorage.getItem(name);
        if (data) {
            if (JSON.parse(data).length) {
                return data;
            } else {
                return false;
            }
        }
        return false;
    }

    if ($(".gallery").length) {
        $(".gallery").fancybox({
            loop: true,
            baseTpl:
                '<div class="fancybox-container" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-navigation">{{arrows}}</div>' +
                '<div class="fancybox-stage"></div>' +
                '<div class="fancybox-caption"></div>' +
                "</div>" +
                "</div>",
        });
    }

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

});
