$(document).ready(function () {

    if ($('.owl-carousel-prices').length) {

        function scrollUp() {
            document.querySelector('#price-inquiry-title').scrollIntoView({
                behavior: 'smooth'
            });
        };
        var change_button = $('#price-inquiry-page').data('change_button');
        var delete_button = $('#price-inquiry-page').data('delete_button');
        var which_step = 0;
        var windows = [];
        var price_inquiry_step = $('#price-inquiry-step').text();
        if (cookie()) {
            $('.has-price-inquiry').toggleClass('hidden', false);
            var value = cookie();

            var value_array = JSON.parse(value);

            value_array.forEach(function (win) {
                windows.push(win);
                makeChosenWindows(win)
            })
        }
        $('.to-form-end-button').click(function () {
            scrollUp();
            $('.owl-carousel-prices').trigger('to.owl.carousel', 2);
            $('#confirm-button').removeClass('hide');
            $('#add-more-button').removeClass('hide');
            $('#confirm-button').text($('[data-inquiry-send]').data('inquiry-send'));
            $('#confirm-button').data('sendform', 'true');
            $('#add-more-button').text($('[data-inquiry-add_more]').data('inquiry-add_more'));
            which_step = 2;
        });

        $('input[name=aknalaud]').click(function () {
            var checked = $(this).prop('checked');
            if (checked) {
                $('.windowsill-form').html('');
                $('.windowsill-form').html($('#windowsill-template').html());
                $('.owl-carousel-prices').resize();
            } else {
                $('.windowsill-form').html('');
                $('.owl-carousel-prices').resize();
            }
        });
        $('input[name=veeplekid]').click(function () {
            var checked = $(this).prop('checked');
            if (checked) {
                $('.veeplekid-form').html('');
                $('.veeplekid-form').html($('#veeplekid-template').html());
                $('.owl-carousel-prices').resize();
            } else {
                $('.veeplekid-form').html('');
                $('.owl-carousel-prices').resize();
            }
        });
        $(document).on('change', 'select[name="door-type"]', function () {
            var val = $(this).val();
            if (val !== 'Taisklaas') {
                $('.vahepost-form').html('');
                $('.vahepost-form').html($('#vahepost-template').html());
                $('.owl-carousel-prices').resize();
            } else {
                $('.vahepost-form').html('');
                $('.owl-carousel-prices').resize();
            }
        });

        $('#need_help_button').click(function () {
            $('.chosen-windows').toggleClass('col-lg-6', false);
            $('.chosen-windows').toggleClass('col-lg-3', true);
            $('.chosen-windows').html('');
            $('.has-price-inquiry').hide();
            $('#add-more-button').hide();
            $('input[name=need_help]').val(true);
        });

        function makeChosenWindows(win) {
            window_type = win[0].value;
            id = win[1].value;
            win.forEach(function (pair) {
                if (pair.name === 'amount') {
                    amount = pair.value;
                }
            });
            window_name = $('#' + window_type).parent().find('.window-type').text();
            $('#chosen-windows').append("<div class='selected-window row' data-window_info='" + JSON.stringify(win) + "' data-unique_id='" + id + "'>" +
                "<div class='col-sm-5 d-flex align-items-center'><div class='window-info' data-window_id='" + id + "'>" + amount + " x " + window_name + "</div></div>" +
                "<div class='col-sm-7 d-flex align-items-center'><div>" +
                "<button class='cta--blue-bg change-button' id='" + id + "'>" + change_button + "</button> <button class='cta--red-bg delete-button' data-unique_delete_id='" + id + "'>" + delete_button + "</button>" +
                "</div></div>"
                + "</div>");
            delegateWindowChange(id);
        }

        function delegateWindowChange(id) {
            $('#chosen-windows').delegate('#' + id, "click", function () {
                {
                    var window_data = $(this).parent().parent().parent().data('window_info');
                    window_type = window_data[0].value;
                    window_name = $('#' + window_type).parent().find('.window-type').text();
                    img = $('#' + window_type).find('img').attr('src');
                    $('#example-window').attr('src', img);
                    $('#price-inquiry-step').text(window_name);
                    unique_id = window_data[1].value;
                    $('.form-measurements').html('');
                    $('.form-measurements').html($('#' + window_type + '-template').html());
                    if (Array.isArray(window_data)) {
                        restoreForm('#window-form', window_data);
                    } else {
                        restoreForm('#window-form', JSON.parse(window_data));
                    }
                    $('.owl-carousel-prices').trigger('to.owl.carousel', 1);
                    $('#confirm-button').text($('[data-inquiry-confirm]').data('inquiry-confirm'));
                    $('#confirm-button').data('sendform', 'false');
                    $('#add-more-button').addClass('hide');
                }
            });
            $('#chosen-windows').delegate('[data-unique_delete_id=' + id + ']', "click", function () {
                {
                    $(this).parent().parent().parent().remove();
                    id = $(this).data('unique_delete_id');
                    windows.forEach(function (win, index) {
                        if (win[1].value === id) {
                            windows.splice(index, 1);
                            cookie(windows);
                            if (windows.length < 1) {
                                $('.owl-carousel-prices').trigger('to.owl.carousel', 0);
                                $('.has-price-inquiry').toggleClass('hidden', true);
                                $('#confirm-button').toggleClass('hide', true);
                                $('#confirm-button').data('sendform', 'false');
                                $('#add-more-button').toggleClass('hide', true);
                                $('#price-inquiry-step').text(price_inquiry_step);
                            }
                        }
                    })

                }
            });
        }

        $('.owl-carousel-prices').owlCarousel({
            'items': 1,
            'autoplay': false,
            'nav': false,
            'dots': false,
            'touchDrag': false,
            'mouseDrag': false,
            'autoHeight': true,
            'dotsContainer': false,
        });
        var window_type = '';
        var img = '';
        var window_name = '';
        $('.choices-row button').click(function () {
            scrollUp();
            $("input").each(function () {
                $(this).toggleClass('invalid', false);
            });
            window_type = $(this).attr('id');
            $('#window-type').val(window_type);
            $('#unique-id').val(makeid());
            img = $(this).find('img').attr('src');
            window_name = $(this).parent().find('.window-type').text();
            $('.form-measurements').html('');
            $('.form-measurements').html($('#' + window_type + '-template').html());

            $('#example-window').attr('src', img);
            $('#price-inquiry-step').text(window_name);
            $('.owl-carousel-prices').trigger('next.owl.carousel');
            $('#confirm-button').removeClass('hide');
            $('#add-more-button').removeClass('hide');
            $('#add-more-button').text($('[data-inquiry-back]').data('inquiry-back'));
            followOpening();

        })
        $('#confirm-button').click(function () {
            scrollUp();
            if ($(this).data('sendform') === 'true') {
                sendform();
                return;
            }
            var window_data = $('#window-form').serializeArray();
            id = window_data[1].value;
            var only_measurements = $('.form-measurements :input').serializeArray();
            var error = false;
            only_measurements.forEach(function (pair) {
                if (pair.value === '') {
                    $("input[name=" + pair.name + "]").toggleClass('invalid', true);
                    error = true;
                } else {
                    $("input[name=" + pair.name + "]").toggleClass('invalid', false);
                }
            });
            if (error) {
                return;
            }
            $('.windowsill-form').html('');
            $('.veeplekid-form').html('');
            $('#window-form')[0].reset();
            $('.owl-carousel-prices').trigger('next.owl.carousel');
            $('#confirm-button').text($('[data-inquiry-send]').data('inquiry-send'));
            $('#confirm-button').data('sendform', 'true');
            $('#add-more-button').removeClass('hide');
            $('#add-more-button').text($('[data-inquiry-add_more]').data('inquiry-add_more'));
            $('.has-price-inquiry').toggleClass('hidden', false);

            if ($('[data-unique_id=' + id + ']').length) {
                $('[data-unique_id=' + id + ']').data('window_info', window_data);
                window_data.forEach(function (pair) {
                    if (pair.name === 'amount') {
                        amount = pair.value;
                    }
                });
                window_type = window_data[0].value;
                window_name = $('#' + window_type).parent().find('.window-type').text();
                $('[data-window_id=' + id + ']').html();
                $('[data-window_id=' + id + ']').html(amount + " x " + window_name);
                delegateWindowChange(id);
                windows.forEach(function (win, index) {
                    if (win[1].value === id) {
                        windows[index] = window_data;
                    }
                })
            } else {
                makeChosenWindows(window_data);
                windows.push(window_data);
            }
            cookie(windows);

            which_step++;
        })

        $('#add-more-button').click(function () {
            scrollUp();
            which_step = 0;
            $('.owl-carousel-prices').trigger('to.owl.carousel', 0);
            $('#confirm-button').addClass('hide');
            $('#confirm-button').text($('[data-inquiry-confirm]').data('inquiry-confirm'));
            $('#confirm-button').data('sendform', 'false');
            $(this).addClass('hide');
            $('#price-inquiry-step').text(price_inquiry_step);
            $('#window-form')[0].reset();
            $('.windowsill-form').html('');
            $('.veeplekid-form').html('');
        });


        function makeid() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 40; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        function restoreForm(form, formArray) {
            formArray.forEach(function (pair) {
                var selector = `input[name="${pair.name}"], textarea[name="${pair.name}"], select[name="${pair.name}"]`
                var input = $(form).find(selector);
                if (pair.name === 'interior-decoration') {
                    $(`input[name="${pair.name}"][value="${pair.value}"]`).prop('checked', 'checked')
                } else if (pair.name === 'aknalaud') {
                    $('.windowsill-form').html('');
                    $('.windowsill-form').html($('#windowsill-template').html());
                    input.prop('checked', 'checked');
                } else if (pair.name === 'veeplekid') {
                    $('.veeplekid-form').html('');
                    $('.veeplekid-form').html($('#veeplekid-template').html());
                    input.prop('checked', 'checked');
                } else {
                    input.val(pair.value);
                }
                followOpening();

            })
        }

        function cookie(windows = null) {
            var name = 'price-inquiry-cookie';
            if (windows) {
                windows_string = JSON.stringify(windows);

                setCookie(name, windows_string, 1)

            } else {
                return getCookie(name);
            }

        };

        function getCookie(name) {
            var data = localStorage.getItem(name);
            if (data) {
                if (JSON.parse(data).length) {
                    return data;
                } else {
                    return false;
                }
            }
            return false;
        }

        function setCookie(name, value, days) {
            localStorage.removeItem(name);
            localStorage.setItem(name, value);
        }

        function deleteCookie(name) {
            setCookie(name, '', -1);
        }

        var img_path = $('#img_path').data('path');

        function followOpening() {
            $('#openings').html('');
            $('#window-form .opening').each(function (e) {
                makeOpening($(this));
            });
            $('.opening').on('change', function (e) {
                makeOpening($(this));
            });
        }

        function makeOpening(opening) {
            var type = opening.data('type');
            ($('.' + type)).each(function () {
                $(this).remove();
            })
            var selected = opening.find('option:selected');
            if (selected.data('opening')) {
                var res = selected.data('opening').split(';');

                res.forEach(function (element) {
                    addOpening(element, type)
                })
            }


        }

        function addOpening(img, type) {

            $('#openings').append('<img src="' + img_path + '/price-inquiry/' + img + '" style="max-width:100%;position:absolute;margin:50px auto;left:0;right:0;" class="' + type + '">')
        }

        function sendform() {
            var form_fields = $('#person-information').serializeArray();

            var error = false;
            var agreed = false;
            form_fields.forEach(function (pair) {
                if (pair.value === '' && pair.name != 'comment') {
                    $("input[name=" + pair.name + "]").toggleClass('invalid', true);
                    $("textarea[name=" + pair.name + "]").toggleClass('invalid', false);
                    $("select[name=" + pair.name + "]").toggleClass('invalid', true);
                    error = true;
                } else {
                    $("input[name=" + pair.name + "]").toggleClass('invalid', false);
                    $("textarea[name=" + pair.name + "]").toggleClass('invalid', false);
                    $("select[name=" + pair.name + "]").toggleClass('invalid', true);
                }
                if (pair.name == 'agree_to_terms') {
                    agreed = true;
                }
            });
            if (!agreed) {
                $("#agree_to_terms").toggleClass('color-red', true);
            } else {
                $("#agree_to_terms").toggleClass('color-red', false);
            }
            if (error || !agreed) {
                return;
            }

            var windows = [];
            console.log(form_fields)

            $('[data-window_info]').each(function () {
                windows.push($(this).data('window_info'))
            })

            $.post(
                ajaxurl,
                {
                    'action': 'send_price_inquiry',
                    'price_inquiry': form_fields,
                    'windows': windows
                },
                function (response) {
                    var resp = JSON.parse(response);
                    if (resp.success) {
                        $('#price-form-row').html('');
                        $('#price-form-row').html('<h3 style="margin:50px auto 20px auto;">' + resp.message + '</h3>');
                        $('#confirm-button').remove();
                        $('#add-more-button').remove();
                        $('.has-price-inquiry').remove();
                        deleteCookie('price-inquiry-cookie');
                    } else {
                        $('.has-price-inquiry').toggleClass('hidden', false);
                        $('.has-price-inquiry').html('');
                        $('.has-price-inquiry').html('<h4 style="margin:50px auto 20px auto;">' + resp.message + '</h4>');
                    }
                }
            );
        }
    }

});
